import React, { useEffect, useState } from 'react'
import Plot from 'react-plotly.js';
import 'bootstrap/dist/css/bootstrap.min.css'

const Graph = ({ id, name }) => {
    //fetching a metric via it's ID.
    const [metric, setMetric] = useState([])
    useEffect(() => {

        const fetchApi = async () => {
            const url = `https://cors-anywhere.herokuapp.com/https://cliffbackend.herokuapp.com/api/metrics/${id}`
            const response = await fetch(url);
            const resJson = await response.json();
            setMetric(resJson);
        }
        fetchApi();
    }, [id])

    //creating arrays from metric data
    const xAxis = []
    const yAxis = []
    const forecasted = []
    const forecasted_x = []
    const minband = []
    const maxband = []
    const anomaly = []
    const anomaly_x = []

    for (var i = 0; i < metric.length; i++) {
        xAxis.push(metric[i]['timestamp'])
        yAxis.push(metric[i]['original_value'])
        minband.push(metric[i]['min_band'])
        maxband.push(metric[i]['max_band'])
        if (metric[i]['forecasted_value'] != null) {
            forecasted.push(metric[i]['forecasted_value'])
            forecasted_x.push(metric[i]['timestamp'])
        }
        if (metric[i]['line_status'] >= 1 && metric[i]['line_status'] <= 3) {
            anomaly.push(metric[i]['original_value'])
            if (metric[i]['line_status'] === 3) {

                anomaly_x.push("null")
            }
            else {
                anomaly_x.push(metric[i]['timestamp'])
            }
        }

    }
    return (
        //rendering graph with the help of react plotly and arrays created above
        <div>
            <Plot
                data={[

                    {
                        x: xAxis, y: minband, fill: 'tonextx',
                        type: 'scatter', name: 'min band',
                        fillcolor: 'grey', color: 'black', marker: { color: 'grey' }
                    },
                    {
                        x: xAxis, y: maxband, fill: 'tonextx',
                        type: 'scatter', name: 'max band',
                        fillcolor: 'grey', color: 'black', marker: { color: 'grey' }
                    },
                    { type: 'line', x: xAxis, y: yAxis, name: 'original value', marker: { color: 'blue' } },
                    { line: { dash: 'dot' }, x: forecasted_x, y: forecasted, name: 'forecasted value', marker: { color: 'blue' } },
                    { line: { dash: 'solid' }, x: anomaly_x, y: anomaly, marker: { color: 'red' }, name: 'Anomalies' }
                ]}
                layout={{
                    font: { color: 'white' }, plot_bgcolor: 'rgb(37, 36, 36)', paper_bgcolor: "rgb(37, 36, 36)"
                    , width: 1200, height: 500, title: `${name}`, xaxis: { title: 'Time ---->' }, yaxis: { title: 'original values ---->' }
                }}
            />
        </div>
    )
}

export default Graph
