import React, { useEffect, useState } from 'react'
import Graph from './components/Graph'
import 'bootstrap/dist/css/bootstrap.min.css'
import Pagination from './components/Pagination'
import './App.css'
const App = () => {
  //fetching all the metrics from backend
  const [metric, setMetric] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const fetchApi = async () => {
      setLoading(true)
      const url = `https://cors-anywhere.herokuapp.com/https://cliffbackend.herokuapp.com/api/metrics`
      const response = await fetch(url);
      const resJson = await response.json();
      setMetric(resJson);
      setLoading(false)
    }
    fetchApi();
  }, [])
  //pagination functionality
  const currentGraph = metric[currentPage];
  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  //rendering UI
  return (
    <div>
      <div className='container'>
        <br />
        <h1 className='text-white'>Greendeck</h1>
        {currentGraph ? (<Graph id={currentGraph._id} name={currentGraph.measure} loading={loading} />) : ("Loading...")}
        <Pagination paginate={paginate} />
      </div>
    </div>
  )
}

export default App
