import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'
const Pagination = ({ paginate }) => {
    //fetching all the metrics from metrics.json
    const [metric, setMetric] = useState([])

    useEffect(() => {
        const fetchApi = async () => {
            const url = `https://cors-anywhere.herokuapp.com/https://cliffbackend.herokuapp.com/api/metrics`
            const response = await fetch(url);
            const resJson = await response.json();
            setMetric(resJson);
        }
        fetchApi();
    }, [])
    return (
        //creating pagination functionality with respect to each metric.  
        <div>
            <nav aria-label="Page navigation example bg-dark">
                <ul class="pagination  justify-content-center bg-dark">
                    {metric.map((item, i) => <li key={item._id} className='page-item bg-dark'><a className='page-link bg-dark' onClick={() => paginate(i)}>{i + 1}</a></li>)}
                </ul>
            </nav>
        </div>
    )

}

export default Pagination
